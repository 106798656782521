<template>
    <div class="retailer">

        <div class="aselect">
            <h3>Please select a retailer</h3>
            <div class="selector" @click="retailerListOpen = !retailerListOpen">
                <div class="label">
                    <span>{{ retailerLabel }}</span>
                </div>
                <div class="arrow" :class="{ expanded: retailerListOpen }"></div>
                <div :class="{ hidden: !retailerListOpen, retailerListOpen }">
                    <ul>
                        <li :class="{ current: item.soldto === (selectedRetailer || {}).soldto }" v-for="item in retailersList" :key="item.id" @click="setRetailer(item)">{{ item.soldto }} - {{ item.soldto_id }}</li>
                    </ul>
                </div>
            </div>
        </div>

        <div class="aselect aselect__bottom" v-if="!retailerListOpen && shipToList.length">
            <h3>Retailer SoldTo</h3>
            <div class="selector" @click="shipToListOpen = !shipToListOpen">
                <div class="label">
                    <span>{{ shipToLabel }}</span>
                </div>
                <div class="arrow" :class="{ expanded: shipToListOpen }"></div>
                <div :class="{ hidden: !shipToListOpen, shipToListOpen }">
                    <ul>
                        <li :class="{ current: item.ship_to_id === (selectedShipTo || {}).ship_to_id }" v-for="item in shipToList" :key="item.id" @click="selectShipTo(item)">{{ item.ship_to_id }} - {{ item.ship_to_name }}</li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="planning__body-block flex-box flex-colum no-box">
            <router-link to="/products" tag="button" class="btn button-primary button-left" :disabled="!selectedRetailer">
                Next - Select products
            </router-link>
        </div>

    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { API } from 'aws-amplify';

import { getRetailers, getAllRetailers, getRetailerData, getShipToData } from '@/graphql/queries';

export default {
    data() {
        return {
            retailerListOpen: false,
            shipToListOpen: false,
            selectedRetailer: null,
            selectedShipTo: null,
            retailersList: [],
            shipToList: [],
        };
    },
    computed: {
        ...mapGetters(['isAdmin', 'salesRepId']),
        retailerLabel() {
            return this.selectedRetailer ? `${ this.selectedRetailer.soldto } - ${ this.selectedRetailer.soldto_id }` : 'Select Retailer';
        },
        shipToLabel() {
            return this.selectedShipTo ? `${ this.selectedShipTo.ship_to_name } - ${ this.selectedShipTo.ship_to_id }` : 'Select ShipTo';
        },
    },
    methods: {
        async getRetailersList() {
            if (this.isAdmin) {
                // GET ALL RETAILERS
                const result = await API.graphql({
                    query: getAllRetailers,
                });
                this.retailersList = result.data.getAllRetailers;
            } else {
                // GET RETAILERS FOR SALES REP ID
                const result = await API.graphql({
                    query: getRetailers,
                    variables: { rep_id: this.salesRepId },
                });
                this.retailersList = result.data.getRetailers;
            }
        },
        setRetailer(obj) {
            if (this.selectedRetailer?.soldto_id !== obj.soldto_id) {
                this.selectedRetailer = obj;
                this.selectedShipTo = null;

                this.getShipToData();

                this.getRetailerData(obj.soldto_id);
            }
        },
        async getRetailerData() {
            // GET FULL RETAILER DATA
            const result = await API.graphql({
                query: getRetailerData,
                variables: { soldto_id: this.selectedRetailer.soldto_id },
            });

            const data = result.data.getRetailerData[0];

            // UPDATE STATE
            this.$store.commit('setSoldTo', data);
        },
        async getShipToData() {
            // GET LIST OF SHIP TO FOR RETAILER
            const result = await API.graphql({
                query: getShipToData,
                variables: { sold_to_id: this.selectedRetailer.soldto_id },
            });
            let data = result.data.getShipToData || [];

            if (data.length > 0) {
                data.unshift({
                    ship_to_id: this.selectedRetailer.soldto_id,
                    ship_to_name: 'SOLD TO',
                });
            }

            this.shipToList = data;
        },
        selectShipTo(obj) {
            if (this.selectedShipTo?.ship_to_id !== obj.ship_to_id) {
                this.selectedShipTo = obj;
                this.$store.commit('setShipTo', obj);
            }
        },
    },
    mounted() {
        // FETCH LIST OF RETAILERS FOR USER
        this.getRetailersList();

        // TODO: CALL THIS AFTER USER SELECTS MONTHS CONFIGURATION
        this.$store.commit('resetSuggestedBooking');
    },
};
</script>
